@use "sass:map";
@use '@angular/material' as mat;
//@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
//@use '@angular/material/core/theming' as mat-theme;
@include mat.core();

//$primary-palette: mat.define-palette(mat.$indigo-palette);

/*$dark-theme: mat.define-dark-theme((
  color: (
    primary: $primary-palette,
  )
));
//$dark-primary-text: mat-theme.text-color($dark-theme, 'primary');
$dark-primary-text: mat.get-color-config($dark-theme, 'primary');*/
$dark-primary-text: rgba(black, 0.87);

/*$light-theme: mat.define-light-theme((
  color: (
    primary: $primary-palette,
  )
));
//$light-primary-text: mat-theme.text-color($light-theme, 'primary');
$light-primary-text: mat.get-color-config($light-theme, 'primary');*/
$light-primary-text: white;

//@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '@angular/material/core/theming/_palette.scss';

//@use '@angular/material/theming' as mat-theme;

//@import '@angular/material/core/theming';

$left_menu_width: 19em;
$button-color: #ff0000;
//$button-color: #2E5BFF;
$back-color: #ef9a9a;
//$back-color: #7eb4e2;
$scrollbar-color: #F0F0F0;
//$third-color: #a0a0a0;
$third-color: #595a5c;

$mat-my-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #ff0a17, //Custom primary color
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  1000: #ff0000,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  D1000: rgba(0, 0, 0, 0.12),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    D1000: $light-primary-text,
  )
);

$size_header_height: 64px;
$size-header-margin-shadow: 0.1em;
$size-footer-height: 32px;
$size-page-height: calc(100vh - #{$size-header-height} - #{$size-header-margin-shadow} - #{$size-footer-height});
$size-map-page-height: calc(100vh - #{$size-header-height} - #{$size-header-margin-shadow});

$layer-zindex-highest: 999;
$layer-zindex-higher: 9;
$layer-zindex-high: 2;
$layer-zindex-above: 1;
$layer-zindex-base: 0;
$layer-zindex-bellow: -1;

/* Angular 17 customisation: https://v17.material.angular.io/guide/theming */
$menu-forecolor: #000000;
$default-font-size: 14px;
::ng-deep * {
  //@include mat.select-density(-22);
  //@include mat.button-density(-22);
  //@include mat.input-density(-22);
  //@include mat.all-component-densities(-22);

  --mat-menu-item-label-text-size: $default-font-size !important;
  --mat-option-label-text-size: $default-font-size !important;
  --mat-optgroup-label-text-size: $default-font-size !important;
  --mdc-filled-text-field-label-text-size: $default-font-size !important;
  --mdc-outlined-text-field-label-text-size: $default-font-size !important;
  --mat-form-field-container-text-size: $default-font-size !important;
  --mat-form-field-outlined-label-text-populated-size: $default-font-size !important;
  --mat-select-trigger-text-size: $default-font-size !important;
  --mdc-dialog-supporting-text-size: $default-font-size !important;
  --mdc-chip-container-shape-radius: $default-font-size !important;
  --mat-menu-item-spacing: $default-font-size !important;
  --mat-menu-item-leading-spacing: $default-font-size !important;
  --mat-menu-item-trailing-spacing: $default-font-size !important;
  --mat-menu-item-with-icon-leading-spacing: $default-font-size !important;
  --mat-menu-item-with-icon-trailing-spacing: $default-font-size !important;
  --mat-menu-item-label-text-size: $default-font-size !important;

  --mat-form-field-container-height: 23.625px !important;
  --mat-form-field-filled-label-display: block !important;
  --mat-form-field-container-vertical-padding: 14px !important;
  --mat-form-field-filled-with-label-container-padding-top: 24px !important;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px !important;
  --mdc-filled-text-field-label-text-font: Outfit, Roboto, sans-serif !important;
  --mdc-filled-text-field-label-text-size: 14px !important;
  --mdc-filled-text-field-label-text-tracking: 0.03125em !important;
  --mdc-filled-text-field-label-text-weight: 400 !important;
  --mdc-outlined-text-field-label-text-font: Outfit, Roboto, sans-serif !important;
  --mdc-outlined-text-field-label-text-size: 14px !important;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em !important;
  --mdc-outlined-text-field-label-text-weight: 400 !important;
  --mat-form-field-container-text-font: Outfit, Roboto, sans-serif !important;
  --mat-form-field-container-text-size: 14px !important;
  --mat-form-field-container-text-tracking: 0.03125em !important;
  --mat-form-field-container-text-weight: 400 !important;
  --mat-form-field-outlined-label-text-populated-size: 14px !important;
  --mat-form-field-subscript-text-font: Roboto, sans-serif !important;
  --mat-form-field-subscript-text-line-height: 20px !important;
  --mat-form-field-subscript-text-size: 12px !important;
  --mat-form-field-subscript-text-tracking: 0.0333333333em !important;
  --mat-form-field-subscript-text-weight: 400 !important;
  --mat-sidenav-content-background-color: #ffffff !important;

  --mdc-text-button-label-text-color: $menu-forecolor !important;
  --mdc-text-button-label-text-tracking: normal !important;

  --mdc-filled-text-field-container-color: #ffffff !important;

  --mat-option-label-text-tracking: normal !important;
  --mat-optgroup-label-text-tracking: normal !important;
  --mdc-filled-text-field-label-text-tracking: normal !important;
  --mdc-outlined-text-field-label-text-tracking: normal !important;
  --mat-form-field-container-text-tracking: normal !important;
  --mat-select-trigger-text-tracking: normal !important;
  --mdc-dialog-supporting-text-tracking: normal !important;
  --mat-menu-item-label-text-tracking: normal !important;
  --mdc-list-list-item-label-text-tracking: normal !important;
  --mat-legacy-button-toggle-label-text-tracking: normal !important;
  --mat-standard-button-toggle-label-text-tracking: normal !important;

  //Background color of mat-drawer-content
  --mat-sidenav-content-text-color: #fafafa !important;
  .mat-drawer-content {
    background-color: #fafafa !important;
  }

  .mat-mdc-form-field {
    --mat-form-field-container-text-line-height: 15.75px !important;
  }
  //--mat-form-field-filled-with-label-container-padding-top: 0px !important;
  //--mat-form-field-filled-with-label-container-padding-bottom: 0px !important;
  //--mat-dialog-actions-padding: 0px !important;
  //--mat-dialog-content-padding: 0px 0px !important;
  //--mat-dialog-with-actions-content-padding: 0px 0px !important;
  //--mat-dialog-headline-padding: 0px 0px 0px !important;
  //
  //--mdc-chip-container-height: 0px !important;

  //--mdc-list-list-item-one-line-container-height: 0px !important;
  //--mdc-list-list-item-two-line-container-height: 0px !important;
  //--mdc-list-list-item-three-line-container-height: 0px !important;
  //--mat-list-list-item-leading-icon-start-space: 0px !important;
  //--mat-list-list-item-leading-icon-end-space: 0px !important;
  //--mat-paginator-container-size: 10px;
  //--mat-paginator-form-field-container-height: 10px;
  //--mat-paginator-form-field-container-vertical-padding: 0px !important;
  //--mdc-tab-indicator-active-indicator-height: 0px !important;
  //--mdc-tab-indicator-active-indicator-shape: 0px !important;
  //--mdc-secondary-navigation-tab-container-height: 0px !important;

  //--mdc-text-button-container-height: 0px !important;
  //--mdc-filled-button-container-height: 0px !important;
  //--mdc-outlined-button-container-height: 0px !important;
  //--mdc-protected-button-container-height: 0px !important;

  //Set height in text fields in forms
  --select-trigger-text-line-height: normal !important;//!
  --mat-form-field-container-text-line-height: normal !important; //!!
  --mat-paginator-container-text-line-height: normal !important; //!!
  --mat-tab-header-label-text-tracking: normal !important;

  //Mat-Icon margin-left
  --mat-text-button-icon-spacing: 0 !important;
  //Mat-Icon margin-right
  --mat-text-button-icon-offset: 0 !important;

  //Input padding top
  --mat-form-field-container-vertical-padding: 18px !important;
  --mat-form-field-filled-with-label-container-padding-top: 18px !important;

  //Input padding bottom
  --mat-form-field-container-vertical-padding: 0 !important;
  //--mat-form-field-filled-with-label-container-padding-bottom: unset !important;

  //Input padding
  .mdc-text-field {
    padding: 0 !important;
  }

  //Mat-error & Mat-info padding
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  //Input validation text
  --mat-form-field-subscript-text-size: 10.5px !important;

  //Table header
  --mat-table-header-headline-size: 12px; //font-size
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.54);

  //Table row
  --mat-table-row-item-container-height: 48px;

  //Table paginator
  --mdc-list-list-item-label-text-size: 12px !important;

  //Icon inside button
  .mat-mdc-button {
    .mat-icon {
      font-size: 22px !important;
      height: 22px !important;
      width: 22px !important;
    }
  }

  .mdc-tab {
    min-width: 160px;
  }

  .mat-calendar-previous-button, .mat-calendar-next-button {
    position: relative !important;
  }

  --mat-form-field-filled-with-label-container-padding-top: 6.125px !important;
  --mat-form-field-filled-with-label-container-padding-bottom: 6.125px !important;
  .mdc-text-field {
    overflow: visible !important;
    margin-top: 8px !important;
  }

  --mat-option-label-text-font:  Roboto !important;

  //Buttons
  --mdc-protected-button-disabled-label-text-color: #00000042 !important;
  --mdc-protected-button-disabled-container-color: #0000001f !important;

  //Label text in dual-drop box
  --mat-sidenav-content-text-color: #000000 !important;

  //Vertical scrollbar in mat-tab
  /*div.mat-mdc-tab-body-content {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }*/

  --mdc-dialog-subhead-color: $button-color;

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 16px;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    --mdc-icon-button-icon-size: var(--mdc-icon-button-state-layer-size);
    //padding-bottom: 16px;
    position: absolute;
    right: 0;
    top: -22px;
  }

  mat-paginator {
    .mdc-icon-button {
      position: relative !important;
      height: 40px !important;
      width: 40px !important;
    }

    .mat-mdc-icon-button svg, .mat-mdc-icon-button img {
      height: 28px;
      width: 28px;
      --mdc-icon-button-icon-size: 28px;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      top: 0px !important;
      padding-top: 0px !important;
    }
  }

  /*.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 5.1px !important;
  }*/

  .mdc-floating-label {
    top: 5.1px !important;
  }

  mat-select {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    position: absolute;
    right: 0;
    top: 8px;
  }

  .mat-mdc-option .mat-pseudo-checkbox-minimal {
    margin-top: -5px;
  }

  mat-option.mat-mdc-option {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  mat-option.mat-mdc-option:hover {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  mat-option.mdc-list-item--selected, mat-option.mat-mdc-list-item--active {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  .mat-mdc-card-header-text {
    width: 100vh !important;
  }
}
/*...Angular 17 */
