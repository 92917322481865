@use '@angular/material' as mat; @import 'variables.scss'; @include mat.core();
//@import '@angular/material/core/theming';
//@include mat-core();

// Set custom theme for emergency
$my-app-primary: mat.define-palette($mat-my-palette);
$my-app-accent: mat.define-palette($mat-my-palette, A200, A100, A400);
//$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);
$my-app-theme: mat.define-light-theme((
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent
    //warn: $my-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));
//$my-app-theme: mat-dark-theme($my-app-primary, $my-app-accent);

//@include angular-material-theme($my-app-theme);
@include mat.all-component-themes($my-app-theme);

/* Custom css */
* { font-family: Outfit !important; }
h1 { font-family: Archivo Narrow !important; }
.mat-button-wrapper:has(mat-icon) { font-family: Roboto !important; }  /* It is important to keep Roboto font for icons without text */
.action-button, .mat-tooltip { font-family: Outfit !important; }
/* Fix pointer cursor to buttons */
button, mat-icon { cursor: pointer; }
html, body { height: 100% }
body { margin: 0; font-family: Outfit, Roboto, "Helvetica Neue", sans-serif !important; }
//div, span, label { font-family: Roboto, "Helvetica Neue", sans-serif !important; }
